<template>
  <div v-if="operationalDashboard && statusSchema" class="operational-dashboard" :class="{'advanced': statusSchema === 'ADVANCED'}">
    <div class="operational-dashboard__item" v-if="statusSchema === 'ADVANCED' || operationalDashboard.assigned">
      <div class="operational-dashboard__item-title">Assigned / Total under work</div>
      <div class="operational-dashboard__item-data">{{ operationalDashboard.assigned }} / {{ operationalDashboard.underWork }}</div>
    </div>
    <div class="operational-dashboard__item" v-else>
      <div class="operational-dashboard__item-title">Total under work</div>
      <div class="operational-dashboard__item-data">{{ operationalDashboard.underWork }}</div>
    </div>
    <div class="operational-dashboard__item">
      <div class="operational-dashboard__item-title">Received</div>
      <div class="operational-dashboard__item-data">{{ operationalDashboard.received }}</div>
    </div>
    <div class="operational-dashboard__item">
      <div class="operational-dashboard__item-title">In progress</div>
      <div class="operational-dashboard__item-data">{{ operationalDashboard.inProgress }}</div>
    </div>
    <div class="operational-dashboard__item" v-if="statusSchema === 'ADVANCED' || operationalDashboard.waitingPmValidation">
      <div class="operational-dashboard__item-title">Waiting pm. validation</div>
      <div class="operational-dashboard__item-data">{{ operationalDashboard.waitingPmValidation }}</div>
    </div>
    <div class="operational-dashboard__item" v-if="statusSchema === 'ADVANCED' || operationalDashboard.waitingResidentValidation">
      <div class="operational-dashboard__item-title">Waiting res. validation</div>
      <div class="operational-dashboard__item-data">{{ operationalDashboard.waitingResidentValidation }}</div>
    </div>
  </div>
</template>

<script>
import NotifyMixin from "@/mixins/NotifyMixin";
import EventBus from "@/utils/EventBus";
import {mapGetters} from "vuex";

export default {
  mixins: [NotifyMixin],

  data() {
    return {
      statusSchema: null,
      operationalDashboard: null,
    };
  },

  computed: {
    ...mapGetters({
      community: 'auth/community',
    })
  },

  methods: {
    processNotifications({channel, body, communityId}) {
      if (communityId !== this.community.id) return;

      switch (channel) {
        case 'SREQ/create_service_request':
        case 'SREQ/update_service_request':
          if (body.value.operationalDashboard.delta) {
            for (const [key, value] of Object.entries(body.value.operationalDashboard.delta)) {
              this.operationalDashboard[key] = this.operationalDashboard[key] + value;
            }
          }
      }
    },
  },

  created() {
    Promise.all([
      this.$sreqDataProvider.getStatusSchema('serviceRequests').then(response => {
        this.statusSchema = response.statusSchema;
      }),
      this.$sreqDataProvider.getOperationalDashboard('serviceRequests').then(response => {
        this.operationalDashboard = response;
      }),
    ]).catch(error => {
      this.notifyError(error.message);
    });
  },

  mounted() {
    EventBus.on('wst-notification', this.processNotifications);
  },

  beforeUnmount() {
    EventBus.off('wst-notification', this.processNotifications);
  },
}
</script>

<style scoped>
.operational-dashboard {
  @apply flex border border-graphite-500 bg-graphite-100 p-4 mb-6;

  .operational-dashboard__item {
    @apply flex border-r border-graphite-400 text-black text-xs px-6;

    &:first-child {
      @apply pl-0;
    }

    &:last-child {
      @apply border-none pr-0;
    }
  }

  .operational-dashboard__item-title {
    @apply mr-2 whitespace-no-wrap;

    &:after {
      content: ':';
    }
  }

  .operational-dashboard__item-data {
    @apply font-semibold;
  }
}

.operational-dashboard.advanced {
  @apply p-5;

  .operational-dashboard__item {
    @apply flex flex-col justify-between text-center flex-grow;

    &:last-child {
      @apply border-none;
    }

    &:first-child {
      @apply pr-12;

      .operational-dashboard__item-title {
        @apply mb-0;
      }
      .operational-dashboard__item-data {
        @apply text-lg font-bold;
      }
    }
  }

  .operational-dashboard__item-title {
    @apply text-xs font-semibold mr-0 mb-2 lowercase;

    &:after {
      display: none;
    }
  }

  .operational-dashboard__item-data {
    @apply text-2sm font-normal;
  }
}
</style>
