<template>
  <InfiniteScrollWrapper
      class="overflow-y-auto"
      ref="scroll"
      :loading="loading"
      :top-threshold="10"
      :bottom-threshold="10"
      @reachedTop="$emit('reachedTop')"
      @reachedBottom="$emit('reachedBottom')"
  >
    <div class="table text-black font-normal w-full">
      <div class="head" ref="head">
        <div class="row">
<!--          <div class="column">-->
<!--            &lt;!&ndash;placeholder for checkbox&ndash;&gt;-->
<!--            <span class="block w-2 h-2"></span>-->
<!--          </div>-->
          <div class="column flex items-center justify-center cursor-pointer" @click="changeSorting(SORT_COLUMN_PRIORITY)">
            <ExclamationCircleIcon class="w-3 h-3"/>
            <Icon :name="getSortIconName(SORT_COLUMN_PRIORITY)" class="ml-2"/>
          </div>
          <div class="column font-bold">request</div>
          <div class="column font-bold flex items-center hover:text-active-600 cursor-pointer" @click="changeSorting(SORT_COLUMN_CREATION_DATE)">
            <span class="mr-2">creation date</span>
            <Icon :name="getSortIconName(SORT_COLUMN_CREATION_DATE)"/>
          </div>
          <div class="column font-bold flex items-center hover:text-active-600 cursor-pointer" @click="changeSorting(SORT_COLUMN_ASSIGNED)">
            <span class="mr-2">assigned to</span>
            <Icon :name="getSortIconName(SORT_COLUMN_ASSIGNED)"/>
          </div>
          <div class="column font-bold flex items-center hover:text-active-600 cursor-pointer" @click="changeSorting(SORT_COLUMN_STATUS)">
            <span class="mr-2">status</span>
            <Icon :name="getSortIconName(SORT_COLUMN_STATUS)"/>
          </div>
          <div class="column font-bold flex items-center hover:text-active-600 cursor-pointer" @click="changeSorting(SORT_COLUMN_CATEGORY)">
            <span class="mr-2">category</span>
            <Icon :name="getSortIconName(SORT_COLUMN_CATEGORY)"/>
          </div>
          <div class="column font-bold"><!--unread messages indicator--></div>
        </div>
      </div>
      <template v-if="requests && requests.length > 0">
        <transition-group name="list" tag="div" class="body" ref="list">
          <template v-for="request in requests" :key="request.id">
            <div
                class="row cursor-pointer"
                :class="{'font-bold': request.hasUpdates, 'expanded': isOpen(request.id), 'selected': isSelected(request.id)}"
                @click="openDetails(request.id)"
            >
              <!--            <label class="column" @click.stop>-->
              <!--              <input-->
              <!--                  type="checkbox"-->
              <!--                  class="w-2 h-2"-->
              <!--                  v-model="request.checked"-->
              <!--                  @change="handleSingleSelection($event)"-->
              <!--                  :disabled="selectionData.counters.total >= 50 && !request.checked"-->
              <!--              >-->
              <!--            </label>-->
              <div class="column text-center"><PriorityIndicator :value="request.priority"/></div>
              <div class="column">
                <div class="overflow-hidden overflow-ellipsis" :title="request.title">
                  <TextHighlighter
                    v-for="(part, index) of request.title.split(':')"
                    :key="`${request.id}-${index}`"
                    :text="part + (index < request.title.split(':').length - 1 ? ':' : '')"
                    :query="searchQuery"
                    :words-mode="true"
                  />
                </div>
                <div
                  class="overflow-hidden overflow-ellipsis font-light"
                  :class="{'text-black': request.hasUpdates || isOpen(request.id), 'text-gray-500': !request.hasUpdates && !isOpen(request.id)}"
                  :title="request.systemTitle"
                >
                  <TextHighlighter
                    v-for="(part, index) of request.systemTitle.split(':')"
                    :key="`${request.id}-${index}`"
                    :text="part + (index < request.systemTitle.split(':').length - 1 ? ':' : '')"
                    :query="searchQuery"
                    :words-mode="true"
                  />
                </div>
              </div>
              <div class="column"><community-date-time-display :input="request.date" format="MM/DD/YY"/></div>
              <div class="column">{{ request.assigned }}</div>
              <div class="column with-icon">
                <div class="text">{{ request.status }}</div>
                <icon v-if="!request.isTerminal && (request.declinationByPmCount > 0 || request.declinationByResidentCount > 0)" name="alert2" class="icon"/>

                <div class="text-gray-500">
                  <community-date-time-display :input="request.stateChangedAt" format="MM/DD/YY"/>
                </div>
              </div>
              <div class="column">
                <div>{{ request.category?.parent?.name || request.category?.name || request.customCategory || '-' }}</div>
                <div v-if="request.category?.parent || (request.category && request.customCategory)" class="text-gray-500">
                  {{ request.category?.parent ? request.category.name : request.customCategory }}
                </div>
              </div>
              <div class="column relative">
                <template v-if="request.unreadMessagesCount > 0">
                  <ChatIcon class="mt-1"/>
                  <span class="indicator-bubble">{{ request.unreadMessagesCount > 9 ? '9+' : request.unreadMessagesCount }}</span>
                </template>
              </div>
            </div>
            <div v-if="isOpen(request.id)" :key="request.id + '-details'" class="details">
              <p class="description mb-3">
                <TextHighlighter
                    :text="request.description"
                    :query="searchQuery"
                    words-mode
                />
              </p>
              <p>
                <router-link :to="{name: 'sreq.details', params: {requestId: request.id}}">View Details</router-link>
                <router-link :to="{name: 'sreq.feed', params: {requestId: request.id}}" class="ml-3">Feed</router-link>
              </p>
            </div>
          </template>
        </transition-group>
      </template>
      <div v-else-if="requests && requests.length === 0" class="flex items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6">
        <span class="flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2">!</span>
        <span class="font-frank font-medium text-sm">{{ emptyResultsText }}</span>
      </div>
    </div>
  </InfiniteScrollWrapper>
</template>

<script>
import InfiniteScrollWrapper from "@/components/list/InfiniteScrollWrapper";
import PriorityIndicator from "@/components/sreq/PriorityIndicator";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import TextHighlighter from "@/components/ui/TextHighlighter";
import Icon from "@/components/ui/Icon";
import ChatIcon from "@/components/ui/icons/ChatIcon";
import {mapActions, mapGetters} from "vuex";
import {
  SORT_COLUMN_ASSIGNED,
  SORT_COLUMN_CATEGORY, SORT_COLUMN_CREATION_DATE,
  SORT_COLUMN_PRIORITY, SORT_COLUMN_STATUS, SORT_DIR_ASC, SORT_DIR_DESC,
  STATUS_TYPE_ALL
} from "@/views/sreq/list/constants";
import {omit, some} from "lodash-es";
import ExclamationCircleIcon from "@/components/ui/icons/ExclamationCircleIcon";

export default {
  name: "RequestsTable",

  components: {
    ExclamationCircleIcon,
    ChatIcon,
    TextHighlighter,
    CommunityDateTimeDisplay,
    PriorityIndicator,
    InfiniteScrollWrapper,
    Icon,
  },

  props: {
    requests: {
      type: Array,
      required: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    searchQuery: {
      type: String,
      required: false
    },

    selectionData: {
      type: Object,
      required: true
    }
  },

  emits: [
    'reachedTop',
    'reachedBottom',
    'update:selectionData',
  ],

  data() {
    return {
      SORT_COLUMN_PRIORITY,
      SORT_COLUMN_ASSIGNED,
      SORT_COLUMN_CATEGORY,
      SORT_COLUMN_CREATION_DATE,
      SORT_COLUMN_STATUS,
      openIds: [],
    };
  },

  watch: {
    requests(requests) {
      if (requests && this.searchQuery) {
        // TODO: optimize for performance
        // IDs of requests with match in description but not in title
        this.openIds = requests
            .filter(request => some(
                request.description.toLowerCase().split(' '),
                (descriptionWord) => some(
                    this.searchQuery.toLowerCase().split(' '),
                    (searchQueryWord) => descriptionWord.startsWith(searchQueryWord)
                )
            ) && !some(
                request.title.toLowerCase().split(' '),
                (descriptionWord) => some(
                    this.searchQuery.toLowerCase().split(' '),
                    (searchQueryWord) => descriptionWord.startsWith(searchQueryWord)
                )
            ))
            .map(({id}) => id);
      } else {
        this.openIds = [];
      }

      const {head, list, scroll} = this.$refs;

      if (!head || !list || !scroll) {
        return;
      }

      const {bottom: headBottom} = head.getBoundingClientRect();

      const firstVisible = Array
        .from(list.$el.childNodes)
        .find(el => el.getBoundingClientRect().bottom > headBottom);

      if (!firstVisible) {
        return;
      }

      const oldSecondVisible = firstVisible.nextElementSibling;

      this.$nextTick(() => {
        if (oldSecondVisible !== firstVisible.nextElementSibling) {
          return;
        }

        scroll.$el.scrollTop += firstVisible.getBoundingClientRect().top - headBottom;
      });
    },
  },

  computed: {
    ...mapGetters({
      filterValues: 'sreq/listFilterValues',
    }),

    emptyResultsText() {
      if (this.filterValues.status !== STATUS_TYPE_ALL) {
        return 'no results';
      }

      for (const value of Object.values(omit(this.filterValues, 'status'))) {
        if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value)) {
          return 'no results';
        }
      }

      return 'no service requests yet';
    },
  },

  methods: {
    ...mapActions({
      setFilterValues: 'sreq/setListFilterValues',
    }),

    isOpen(requestId) {
      return this.openIds.find(id => id === requestId) !== undefined;
    },

    openDetails(requestId) {
      if (this.isOpen(requestId)) {
        this.openIds = this.openIds.filter(id => id !== requestId);
      } else {
        this.openIds = [...this.openIds, requestId]
      }
    },

    handleSingleSelection() {
      const selected = this.requests.filter(({checked}) => checked);

      const selectionData = {
        ids: selected.map(({id}) => id),
        counters: {
          total: selected.length,
          basic: selected.filter(({statusSchema}) => statusSchema === 'BASIC').length,
          advanced: selected.filter(({statusSchema}) => statusSchema === 'ADVANCED').length,
        },
      }

      this.$emit('update:selectionData', selectionData);
    },

    isSelected(requestId) {
      return this.selectionData.ids.find(id => id === requestId) !== undefined;
    },

    getSortIconName(columnName) {
      if (this.filterValues.sort?.name === columnName) {
        if (this.filterValues.sort?.dir === SORT_DIR_ASC) {
          return 'sortAsc';
        }

        if (this.filterValues.sort?.dir === SORT_DIR_DESC) {
          return 'sortDesc';
        }
      }

      return 'sortNone';
    },

    changeSorting(columnName) {
      const newSort = {
        name: this.filterValues.sort?.name,
      };

      if (this.filterValues.sort?.name === columnName) {
        newSort.dir = this.filterValues.sort?.dir === SORT_DIR_ASC ? SORT_DIR_DESC : SORT_DIR_ASC;
      } else {
        newSort.name = columnName;

        switch (columnName) {
          case SORT_COLUMN_PRIORITY:
          case SORT_COLUMN_ASSIGNED:
          case SORT_COLUMN_CATEGORY:
          case SORT_COLUMN_STATUS:
            newSort.dir = SORT_DIR_ASC;
            break;
          case SORT_COLUMN_CREATION_DATE:
            newSort.dir = SORT_DIR_DESC;
            break;
        }
      }

      this.setFilterValues({...this.filterValues, sort: newSort});
    },
  },
}
</script>

<style scoped>
.row.list-enter-from, .row.list-leave-to {
  background: #ebf4ff;
}
.row.list-enter-active, .row.list-leave-active {
  transition: all .5s;
}

.row {
  display: grid;
  /*grid-template-columns: auto minmax(2rem, 3.125rem) 3fr 7.5rem 2fr 2fr 2fr 2.125rem;*/
  grid-template-columns: minmax(2rem, 3.125rem) 3fr 7.5rem 2fr 2fr 2fr 2.125rem;
}

.head {
  position: sticky;
  top: 0;
  z-index: 1;
}

.head .row {
  background: #ffffff;
  border-bottom: 1px solid black;
}

.head .row .column {
  @apply font-frank;
  font-size: .6875rem;
  padding: .625rem;
}

.body .row:nth-child(even) {
  background: #F4F4F4;
}
.body .row.selected {
  @apply bg-blue-300;
}

.body .row .column {
  font-size: .75rem;
  border-bottom: none;
  padding: .625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body .row .column.with-icon {
  position: relative;
  padding-right: 1.5rem;
}

.body .row .column .text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.body .row .column .icon {
  position: absolute;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  @apply text-red-900;
}

.body .row.expanded, .details {
  background: #EDF8FF;
}

.row.expanded {
  border-left: 1px solid #D1E8F8;
  border-top: 1px solid #D1E8F8;
  border-right: 1px solid #D1E8F8;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.details {
  display: block;
  padding: 0 .625rem 1rem 2.75rem;
  font-size: .625rem;
  color: #4F98CA;
  border-left: 1px solid #D1E8F8;
  border-bottom: 1px solid #D1E8F8;
  border-right: 1px solid #D1E8F8;
}

.details a {
  text-decoration: underline;
}
.details a:hover {
  text-decoration: none;
}

.indicator-bubble {
  @apply rounded-full border border-white text-white bg-blue-500 font-medium;
  @apply absolute top-1 right-0 w-4 h-4 flex items-center justify-center pb-px;
  font-size: 9px;
}
</style>
