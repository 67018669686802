<template>
  <div class="content">
    <OperationalDashboard class="flex-none overflow-auto"/>
    <FiltersBlock/>
    <div class="selection-data-label" v-if="selectionData.counters.total > 0">
      <span class="mr-3">Selected</span>
      <span v-if="selectionData.counters.basic > 0 && selectionData.counters.advanced > 0">basic scheme:</span>&nbsp;
      <span v-if="selectionData.counters.basic > 0" class="font-bold mr-3">{{ selectionData.counters.basic }}</span>
      <span v-if="selectionData.counters.basic > 0 && selectionData.counters.advanced > 0">advanced scheme:</span>&nbsp;
      <span v-if="selectionData.counters.advanced > 0" class="font-bold">{{ selectionData.counters.advanced }}</span>
    </div>
    <RequestsTable
      :requests="requests"
      :loading="isLoading"
      :searchQuery="filterValues.searchQuery || ''"
      @reachedTop="getPreviousPage"
      @reachedBottom="getNextPage"
      v-model:selection-data="selectionData"
    />
  </div>
</template>

<script>
  import RequestsTable from "@/components/sreq/list/RequestsTable";
  import AuthMixin from "@/components/auth/AuthMixin";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import SreqMixin from "@/mixins/SreqMixin";
  import EventBus from "@/utils/EventBus";
  import FiltersBlock from "@/components/sreq/list/FiltersBlock";
  import {SORT_DIR_ASC, SORT_DIR_DESC, STATUS_TYPE_OPEN} from "@/views/sreq/list/constants";
  import {STATUS_COMPLETED, STATUS_CANCELED} from "@/views/sreq/requests/constants";
  import {mapActions, mapGetters} from "vuex";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import OperationalDashboard from "@/components/sreq/list/OperationalDashboard";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import {isEmpty} from "lodash-es";

  const convertRequestItem = ({value, token}) => {
    return {
      token,
      id: value.id,
      title: value.data.title,
      systemTitle: value.systemTitle,
      date: value.createdAt,
      priority: value.metadata.priority.level,
      status: value.metadata.currentState.name,
      stateChangedAt: value.metadata.stateChangedAt,
      isTerminal: value.metadata.currentState.id === STATUS_COMPLETED || value.metadata.currentState.id === STATUS_CANCELED,
      description: value.data.description,
      category: value.metadata.category,
      customCategory: value.metadata.customCategory,
      assigned: value.metadata.assignee ? `${value.metadata.assignee.firstName} ${value.metadata.assignee.lastName}`: '–',
      declinationByPmCount: value.metadata.declinationByPmCount,
      declinationByResidentCount: value.metadata.declinationByResidentCount,
      hideDeclinationByPmMessage: value.metadata.hideDeclinationByPmMessage,
      hideDeclinationByResidentMessage: value.metadata.hideDeclinationByResidentMessage,
      hasUpdates: false,
      statusSchema: value.metadata.statusSchema,
      chatId: value.metadata.chat.id,
      unreadMessagesCount: value.metadata.chat.stat?.totalUnreadMessages || 0,
    };
  };

  export default {
    components: {
      OperationalDashboard,
      FiltersBlock,
      RequestsTable
    },
    mixins: [AuthMixin, ModalNavigation, SreqMixin, ActionsMixin, NotifyMixin],
    data() {
      return {
        requests: null,
        requestsMap: new Map(),
        isLoading: false,
        pageSize: 20,
        maxItems: 100,
        hasPrevious: false,
        hasNext: false,
        sortKey: 'priority',
        sortDirection: SORT_DIR_ASC,
        selectionData: {
          ids: [],
          counters: {
            total: 0,
            basic: 0,
            advanced: 0,
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        filterValues: 'sreq/listFilterValues',
        tmpBuffer: 'sreq/tmpBuffer',
      }),
    },
    watch: {
      'community': function () {
        if (!this.hasSreqPermission('SRV')) {
          this.$router.push({ name: 'bookmarks' });
        } else {
          this.initialize();
        }
      },
    },
    methods: {
      ...mapActions({
        setFilterValues: 'sreq/setListFilterValues',
        setTmpBuffer: 'sreq/setTmpBuffer',
        resetTmpBuffer: 'sreq/resetTmpBuffer',
      }),
      async getNextPage() {
        if (!this.hasNext) return;

        this.isLoading = true;

        const items = (await this.$sreqDataProvider.getList(
          'serviceRequests',
          this.makeRequestParameters(this.requests[this.requests.length - 1].token, 'FORWARD')
        )).items.map(convertRequestItem);

        if (items.length) {
          for (const item of items) {
            this.requestsMap.set(item.id, item);
          }
          this.prepareSortedArray();

          if (this.requests.length > this.maxItems) {
            for (const item of this.requests.slice(0, items.length)) {
              this.requestsMap.delete(item.id);
            }
            this.requests = this.requests.slice(items.length);
            this.hasPrevious = true;
          }
        }

        if (items.length < this.pageSize) {
          this.hasNext = false;
        }

        this.isLoading = false;
      },
      async getPreviousPage() {
        if (!this.hasPrevious) return;

        this.isLoading = true;

        const items = (await this.$sreqDataProvider.getList(
          'serviceRequests',
          this.makeRequestParameters(this.requests[0].token, 'BACK')
        )).items.map(convertRequestItem);

        if (items.length) {
          for (const item of items) {
            this.requestsMap.set(item.id, item);
          }
          this.prepareSortedArray();

          if (this.requests.length > this.maxItems) {
            for (const item of this.requests.slice(-items.length)) {
              this.requestsMap.delete(item.id);
            }
            this.requests = this.requests.slice(0, -items.length);
            this.hasNext = true;
          }
        }

        if (items.length < this.pageSize) {
          this.hasPrevious = false;
        }

        this.isLoading = false;
      },
      prepareSortedArray() {
        this.requests = [...this.requestsMap.values()].sort((a, b) => {
          if (a.token === b.token) return 0;

          let comparisonResult = a.token < b.token;

          if (this.sortDirection === SORT_DIR_DESC) comparisonResult = !comparisonResult;

          return comparisonResult ? -1 : 1;
        });
      },
      async initialize() {
        try {
          // set data values to initial ones
          this.requests = null;
          this.requestsMap = new Map();
          this.isLoading = false;
          this.pageSize = 20;
          this.maxItems = 100;
          this.hasPrevious = this.tmpBuffer?.hasPrevious || false;
          this.hasNext = this.tmpBuffer?.hasNext || false;
          this.sortKey = 'priority';
          this.sortDirection = this.filterValues.sort?.dir ?? SORT_DIR_ASC;
          this.selectionData = {
            ids: [],
            counters: {
              total: 0,
              basic: 0,
              advanced: 0,
            },
          };

          const pendingRequestQuery = this.filterValues.searchQuery;

          this.isLoading = true;
          const requests = this.tmpBuffer?.requests || (await this.$sreqDataProvider.getList('serviceRequests', this.makeRequestParameters()))
              .items.map(convertRequestItem);

          if (pendingRequestQuery !== this.filterValues.searchQuery) {
            return;
          }

          this.requests = requests;

          this.hasNext = this.requests.length >= this.pageSize;
          for (const request of this.requests) {
            this.requestsMap.set(request.id, request);
          }

          if (this.hasFilters() && this.requests.length > 0 && this.hasSreqPermission('SRPr')) {
            this.addActionToStore({
              routeName: this.$route.name,
              item: {
                id: 'sreq-bulk-printing',
                title: 'print',
                routeName: 'sreq.print.bulk',
              },
            });
          } else {
            this.removeActionFromStore({
              title: 'print',
              routeName: this.$route.name,
            });
          }

          this.resetTmpBuffer();
        } catch (error) {
          this.notifyError(error.message);
        } finally {
          this.isLoading = false;
        }
      },

      hasFilters() {
        const {
          createdAt,
          assignee,
          status,
          priorities,
          searchQuery,
        } = this.filterValues;

        return status !== 'ALL' || createdAt || assignee || searchQuery || priorities?.length > 0;
      },

      processNotifications(notification) {
        switch (notification.channel) {
          case 'SREQ/create_service_request':
          case 'SREQ/update_service_request': {
            if (notification.communityId !== this.community.id) return;

            const token = this.filterValues.sort?.name ?
                notification.body.sortTokens[this.filterValues.sort.name] :
                notification.body.token;
            const request = convertRequestItem({value: notification.body.value, token});
            this.requestsMap.set(request.id, request);
            this.prepareSortedArray();
            break;
          }
          case 'CHAT/update_chats_stat': {
            for (const updateInfo of notification.body) {
              const request = this.requests.find(r => r.chatId === updateInfo.chatId);
              if (request) {
                request.unreadMessagesCount = updateInfo.stat.totalUnreadMessages;
                this.requestsMap.set(request.id, request);
              }
            }
            break;
          }
        }
      },
      makeRequestParameters(token, dir) {
        const params = {
          dir,
          token,
          limit: this.pageSize,
        };

        if (this.filterValues.createdAt) {
          params.creationDateFrom = this.filterValues.createdAt.startDate;
          params.creationDateTo = this.filterValues.createdAt.endDate;
        }

        if (this.filterValues.assignees) {
          params.assignees = this.filterValues.assignees;
        }

        if (this.filterValues.categories) {
          params.categories = this.filterValues.categories;
        }

        if (this.filterValues.statuses) {
          params.statuses = this.filterValues.statuses;
        }

        if (this.filterValues.priorities) {
          params.priorities = this.filterValues.priorities;
        }

        if (this.filterValues.searchQuery) {
          params.q = this.filterValues.searchQuery;
        }

        if (this.$route.query.locationId) {
          params.locationId = this.$route.query.locationId;
        }

        if (this.filterValues.sort?.name) {
          params.sort = this.filterValues.sort.name;
        }

        if (this.filterValues.sort?.dir) {
          params['sort.dir'] = this.filterValues.sort.dir;
        }

        return params;
      },
    },
    created() {
      if (this.$route.query.locationName) {
        this.setActiveModalTitle(`${this.$route.query.locationName} service requests`);
      }
      if (this.$route.query.status) {
        this.setFilterValues({statuses: [this.$route.query.status]});
      }
      if (isEmpty(this.filterValues)) {
        this.setFilterValues({statuses: STATUS_TYPE_OPEN});
      }

      this.unwatchFilterValues = this.$watch('filterValues', () => {
        this.initialize();
      });

      this.initialize();
    },
    mounted() {
      EventBus.on('wst-notification', this.processNotifications);
    },
    beforeUnmount() {
      EventBus.off('wst-notification', this.processNotifications);
    },
    beforeRouteLeave(to) {
      if (this.unwatchFilterValues) {
        this.unwatchFilterValues();
      }

      if (to.meta.product !== 'sreq') {
        this.setFilterValues({});
        this.resetTmpBuffer();
      } else {
        this.setTmpBuffer({
          hasPrevious: this.hasPrevious,
          hasNext: this.hasNext,
          requests: this.requests,
        });
      }
    },
  };
</script>

<style scoped>
.content {
  @apply flex flex-col h-full overflow-x-hidden overflow-y-auto;
}

.selection-data-label {
  @apply font-medium text-2xs;
}
</style>
